import React from "react"
import tw from "twin.macro"
import { Layout } from "components/Layout"
import styled from "styled-components"

const StyledPage = styled.div`
  ${tw`max-w-screen-md mx-auto`}
`

const NotFoundPage = () => (
  <Layout>
    <StyledPage>
      <h1>NOT FOUND</h1>
      <p>Page not found</p>
    </StyledPage>
  </Layout>
)

export default NotFoundPage
